body {
  margin: 0;
}

*:not(
    .MuiModal-root *,
    .MuiSnackbar-root *,
    .MuiLoadingButton-root,
    .MuiLoadingButton-root *,
    .MuiPickersPopper-root,
    .MuiPopper-root
  ) {
  transition: 0.4s ease-in-out !important;
}

a {
  cursor: pointer !important;
  text-decoration: none;
  text-transform: none !important;
  /* &:hover {
    transform: scale(1.01);
  } */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
a {
  font-family: Roboto, sans-serif !important;
}
